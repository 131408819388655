import * as React from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading"
import Link from "gatsby"

function SectionDivider({ name }) {
    return (
        <div className="
        bg-pas-peach text-pas-white
        inline-block  align-middle
        w-full py-5 mb-10 md:mb-14
        font-ovo text-center text-lg md:text-xl tracking-widest leading-4
        rounded-sm">
            {name}
        </div>
    )
}

function Contact() {
    return (
        <>
            <Seo title="Contact" />
            <BrandHeading title="contact us" />
            <section className="flex flex-wrap justify-evenly">
                <SectionDivider name={"WE'RE HERE TO HELP!"} />
                <div className="max-w-4xl">
                    <p className="block w-full font-gotu text-center text-xs md:text-[.80rem] tracking-wider md:tracking-[.06em] leading-6 md:leading-8 text-pas-gray mb-7">
                       For any order enquiries or special requests, reach out to us anytime and we will happily assist you as best we can!</p>
                    <h3 className="block w-full font-ovo text-center text-base tracking-wider text-gray-800 mb-3">EMAIL</h3>
                    <p className="block w-full font-gotu text-center text-xs md:text-[.80rem] tracking-wider md:tracking-[.06em] mb-7 md:mb-10">
                    <a href='mailto:hello@pasteleria.co.nz' className='text-pas-orange cursor-pointer'>hello@pasteleria.co.nz</a>
                        
                    </p>
                    <h3 className="block w-full font-ovo text-center text-base tracking-wider text-gray-800 mb-3">PHONE</h3>
                    <p className="block w-full font-gotu text-center text-xs md:text-[.80rem] tracking-wider md:tracking-[.06em] mb-12 md:mb-14">
                    <a href='tel:021-029-17082' className='text-pas-orange cursor-pointer'>(022) 189 5433</a>
                        
                    </p>
                    <h3 className="block w-full font-ovo text-center text-lg md:text-xl tracking-wider text-gray-800 mb-3 md:mb-5">OR</h3>
                    <div className="w-full flex flex-row">
                        <hr className="flex-auto mt-3  text-pas-peach border-pas-peach border-t-1" />
                        <h3 className="flex-none px-6 font-ovo text-center text-lg md:text-xl tracking-widest text-gray-900 mb-7 md:mb-10">CONNECT WITH US!</h3>
                        <hr className="flex-auto mt-3  text-pas-peach border-pas-peach border-t-1" />
                    </div>

                    <p className="block w-full font-gotu text-center text-xs md:text-[0.80rem] tracking-wider md:tracking-[.06em] leading-6 md:leading-8 text-pas-gray mb-7">
                       We love hearing about our customers enjoying our products! So TAG US in your posts and FOLLOW our socials to stay up-to-date with our product launches, giveaways and more!</p>
                    <h3 className="block w-full font-ovo text-center text-base tracking-wider text-gray-800 mb-3">INSTAGRAM</h3>
                    <p className="block w-full font-gotu text-center text-xs md:text-[.80rem] tracking-wider md:tracking-[.06em] mb-7 md:mb-10">
                        <a href='https://www.instagram.com/pastelerianz/' target="_blank" className='text-pas-orange cursor-pointer'>@pastelerianz</a></p>
                    <h3 className="block w-full font-ovo text-center text-base tracking-wider text-gray-800 mb-3">FACEBOOK</h3>
                    <p className="block w-full font-gotu text-center text-xs md:text-[.80rem] tracking-wider md:tracking-[.06em] mb-10">
                        <a href='https://www.facebook.com/pasteleriaNZ' target="_blank" className='text-pas-orange cursor-pointer'>Pasteleria NZ / @pastelerianz</a>
                    </p>
                </div>
            </section>
        </>
    )
}

export default Contact
